import React from "react";
import Header from "./components/Header/Header";
import Contact from "./components/Contact/Contact"
import Footer from "./components/Footer/Footer";
import Team from "./components/Team/Team";
import Blogs from "./components/Blogs/Blogs";
import People from "./components/People/People";
import SignInForm from "./components/Admin/SignInForm/SignInForm";


function App() { 
  return (
      <div>
        {/* <Header />
        <People />
        <Team />
        <Contact />
        <Footer /> */}

        {/* admin panel */}

        <Header />
        <SignInForm />
        {/* blogging panel */}
        <Blogs/>
    </div>
  );
}

export default App;
