import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import firebase from "firebase/compat/app";


firebase.initializeApp ({
  apiKey: "AIzaSyCFiv2rLWwmpV74p1z0HeZ6HYq9W4HAqaQ",
  authDomain: "geekytwin-30c9b.firebaseapp.com",
  projectId: "geekytwin-30c9b",
  storageBucket: "geekytwin-30c9b.appspot.com",
  messagingSenderId: "822056480798",
  appId: "1:822056480798:web:827ac5971a253bb4f0be0f",
  measurementId: "G-3YLHYVNPK0"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
